import React from 'react'
import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import { ThemeProvider } from '@uniqs-labs/ui-react-lib'
import { AppProps } from 'next/app'

// global styles
import '@uniqs-labs/ui-react-lib/styles'
import '../styles/global-override.css'

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="We focus on solving everyday problems by building software applications with its end-users at heart."
        />
        <meta name="keywords" content="Agile Scrum Master Stand-up Daily" />
        <link href="/favicon.ico" rel="icon" type="image/ico" sizes="16x16" />
        <link href="/favicon.ico" rel="icon" type="image/ico" sizes="32x32" />
        <title>UNIQS</title>
      </Head>
      <ThemeProvider>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
